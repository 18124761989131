import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/meta/seo';
import Logo from '../components/layout/header/logo';
import ArrowRight from '../assets/arrow-right.svg';

const SpliceTemplates = ({ location }) => {
  const [dynamicLink, setDynamicLink] = useState(
    'https://splice.page.link/' +
      `?link=${encodeURIComponent('https://spliceapp.com/template?id=&sig=')}` +
      '&isi=409838725' +
      '&ibi=com.path36.SpliceFree' +
      '&efr=1' +
      `&ofl=${encodeURIComponent('https://spliceapp.com')}`
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const sig = params.get('sig');
    const link =
      'https://splice.page.link/' +
      `?link=${encodeURIComponent(
        `https://spliceapp.com/template?id=${id}&sig=${sig}`
      )}` +
      '&isi=409838725' +
      '&ibi=com.path36.SpliceFree' +
      '&efr=1' +
      `&ofl=${encodeURIComponent('https://spliceapp.com')}`;
    setDynamicLink(link);
  }, [location.search, setDynamicLink]);

  return (
    <div className="dark section-dark min-h-screen flex flex-col">
      <header>
        <SEO title="Splice Templates" />
        <h1 className="leading-none mt-7 w-20 ml-5">
          <Logo />
        </h1>
      </header>
      <main className="flex-grow grid grid-cols-1 content-center justify-items-center">
        <h2 className="B32 mb-4">
          Splice&nbsp;
          <span className="text-gradient bg-splice-gradient-horizontal">
            Templates
          </span>
        </h2>
        <div>
          <p className="M18 mb-0 text-center">Create impressive videos.</p>
          <p className="M18 text-center">In a matter of seconds.</p>
        </div>
        <a
          href={dynamicLink}
          className="btn btn--blue mt-14 py-4 px-8 contrast-high rounded-full"
        >
          <div className="lg:hidden SB20">Open the template</div>
          <div className="hidden lg:inline SB20">Get to know Splice</div>
          <ArrowRight />
        </a>
        <p className="hidden M16 lg:inline text-center mt-8">
          Open this page on your mobile to start editing your video from this template.
        </p>
      </main>
    </div>
  );
};

SpliceTemplates.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default SpliceTemplates;
